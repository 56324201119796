<template>
  <div class="settings-page">
    <div>
      <div>
        <h1 class="mb-6">Settings</h1>
        <div class="flex items-center gap-6 mb-8 overflow-x-auto pb-3 sm:pb-0">
          <button data-cy="account-tab" @click="activeTab = 0" class="text-base font-medium inline-flex items-center rounded-3xl" style="outline: none" :class="[ activeTab == 0 ? 'bg-black text-white px-4 py-2' : '' ]">Account</button>
          <button v-if="!isUserManaging" data-cy="users-tab" @click="activeTab = 1" class="text-base font-medium inline-flex items-center rounded-3xl" style="outline: none" :class="[ activeTab == 1 ? 'bg-black text-white px-4 py-2' : '' ]">Users</button>
          <button data-cy="connections-tab" @click="activeTab = 2" class="text-base font-medium inline-flex items-center rounded-3xl" style="outline: none" :class="[ activeTab == 2 ? 'bg-black text-white px-4 py-2' : '' ]">Connections</button>
          <button data-cy="composer-tab" @click="activeTab = 3" class="text-base font-medium inline-flex items-center rounded-3xl" style="outline: none" :class="[ activeTab == 3 ? 'bg-black text-white px-4 py-2' : '' ]">Composer</button>
          <button data-cy="notifications-tab" @click="activeTab = 4" class="text-base font-medium inline-flex items-center rounded-3xl" style="outline: none" :class="[ activeTab == 4 ? 'bg-black text-white px-4 py-2' : '' ]">Notifications</button>
        </div>
        <div v-if="activeTab == 0" class="space-y-8">
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-lg">
            <h3>Theme</h3>
            <div class="flex items-center md:gap-5 gap-x-3">
              <label
                for="light"
                class="sm:px-8 sm:py-6 px-4 py-3  rounded-lg cursor-pointer"
                :class="[ theme === 'light' ? 'border-2 border-main-color-100' : 'border border-gray-90 bg-white text-main-color-100' ]"
              >
                <input id="light" type="radio" value="light" class="hidden" v-model="theme" />
                <span class="text-lg">Light</span>
              </label>
              <label
                for="dark"
                class="sm:px-8 sm:py-6 px-4 py-3 rounded-lg cursor-pointer bg-black text-white"
                :class="[ theme === 'dark' ? 'border-2 border-main-color-100' : 'border border-black' ]"
              >
                <input id="dark" type="radio" value="dark" class="hidden" v-model="theme" />
                <span class="text-lg">Dark</span>
              </label>
              <label
                for="sync-with-system"
                class="sm:px-8 sm:py-6 px-4 py-3 rounded-lg cursor-pointer bg-main-color-50 text-main-color-100"
                :class="[ theme === 'sync-with-system' ? 'border-2 border-main-color-100' : '' ]"
              >
                <input id="sync-with-system" type="radio" value="sync-with-system" class="hidden" v-model="theme" />
                <span class="text-lg sm:inline hidden">Sync with system</span>
                <span class="text-lg sm:hidden">System</span>
              </label>
            </div>
          </div>
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl">
            <h3>Timezone</h3>
            <vue-select
              class="text-base"
              dataCy="settings-timezone"
              autocomplete="on"
              :model="timezone"
              @input="updateTimezone"
              :options="allTimeZones"
            />
          </div>
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl">
            <h3>Email</h3>
            <input type="text" class="form-field" :value="userProfile.email" disabled />
            <p class="text-base px-2">
              If you want to update your email address, please contact
              <a
                v-on:click.prevent.self onclick="$crisp.push(['do', 'chat:open'])"
                href="#"
                class="text-main-color-100"
              >support</a>.
            </p>
          </div>
          <div v-if="isAllowedToDeleteAccount" class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl bg-alert-warning">
            <h3>Delete Account</h3>
            <p class="text-base">Delete your Hypefury account and all data associated with it.</p>
              <new-button
                @click="openDeleteAccountModal()"
              >
                Delete Account
              </new-button>
          </div>
          <div class="app-settings-section border border-gray-90 p-6 rounded-2xl">
            <h3 class="text-2xl font-bold mb-6">Legal</h3>
            <p class="text-base px-2">
              To review the rules and conditions for using our app, check our
              <a
                href="https://hypefury.com/terms-of-service/"
                class="text-main-color-100"
              >Terms of Service</a>.
            </p>
            <p class="text-base px-2">
              To understand how we collect, use, and protect your personal information, check our
              <a
                href="https://hypefury.com/privacy-policy/"
                class="text-main-color-100"
              >Privacy Policy</a>.
            </p>
          </div>
        </div>
        <div v-if="activeTab == 1 && !isUserManaging" class="space-y-8">
          <div class="app-settings-section border border-gray-90 p-6 rounded-2xl">
            <div class="space-y-3 mb-4" v-if="userProfile.isSecondaryAccount() && Object.keys(userProfile.parentsCustomerStatus).includes(currentUser.uid)">
              <h3 class="mb-4">Currently connected to:</h3>
              <ul class="space-y-2 m-0">
                <li
                  class="flex items-center justify-between">
                  <div class="flex items-center justify-between space-x-4">
                    <img width="24" height="24" :src="currentUser.providerData[0].photoURL" class="w-6 h-6 rounded-full overflow-hidden flex-shrink-0" />
                    <span>{{ currentUser.providerData[0].displayName }}</span>
                  </div>
                  <base-button
                    @click="disconnectCurrentAccount()"
                    type="primary"
                    size="sm"
                    class="button small-button secondary-danger"
                  >
                    Disconnect
                  </base-button>
                </li>
              </ul>
              <hr />
            </div>
            <div class="space-y-3 mb-4" v-if="!userProfile.isSecondaryAccount() && otherConnectedUserProfiles.length > 0">
              <h3 class="mb-4">Additional accounts</h3>
              <ul class="space-y-2 m-0">
                <li
                  v-bind:key="profile.uid"
                  v-for="profile in otherConnectedUserProfiles"
                  class="bg-card rounded-lg py-1 pl-4 pr-1 flex items-center justify-between">
                  <div class="flex items-center justify-between space-x-4">
                    <img width="24" height="24" :src="profile.photoURL" class="w-6 h-6 rounded-full overflow-hidden flex-shrink-0" />
                    <span>@{{profile.username}}</span>
                  </div>
                  <base-button
                    @click="disconnectAdditionalAccount(profile.uid)"
                    type="primary"
                    size="sm"
                    class="button small-button secondary-danger"
                  >
                    Disconnect
                  </base-button>
                </li>
              </ul>
              <hr />
            </div>
            <div class="space-y-4" v-if="userProfile.managedAccounts.length > 0">
              <h3>Managing account</h3>
              <p class="text-secondary">
                You are managing the following accounts
              </p>
              <div>
                <ul class="space-y-2 m-0">
                  <li
                    v-bind:key="managedAccount.uid"
                    v-for="managedAccount in userProfile.managedAccounts"
                    class="flex items-center justify-between">
                    <div class="flex items-center justify-between space-x-4">
                      <img width="24" height="24" :src="managedAccount.profileInfo.photoURL" class="w-6 h-6 rounded-full overflow-hidden flex-shrink-0" />
                      <span>@{{managedAccount.profileInfo.screen_name}}</span>
                    </div>
                    <base-button
                      @click="quitAsManager(managedAccount.uid)"
                      type="primary"
                      size="sm"
                      class="button small-button secondary-button"
                      :loading="removingManagedAccounts.includes(managedAccount.uid)"
                    >
                      Disconnect
                    </base-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="space-y-4">
              <h3 ref="#account-managers">Account managers</h3>
              <p class="text-base">
                Invite your assistant or team member to manage your Hypefury account.
              </p>
              <div>
                <ul class="space-y-2 m-0">
                  <li
                    v-bind:key="manager.uid"
                    v-for="manager in userProfile.managers"
                    class="flex items-center justify-between">
                    <div class="flex items-center justify-between space-x-4">
                      <img width="24" height="24" :src="manager.photoURL" class="w-6 h-6 rounded-full overflow-hidden flex-shrink-0" />
                      <span>@{{manager.username}}</span>
                    </div>
                    <div class="flex space-x-2">
                      <tooltip
                        :content="`${manager.cannotSchedulePosts ? 'Allow' : 'Prevent'} ghostwriter to schedule tweets directly without your validation`"
                      >
                        <base-button
                          @click="manager.cannotSchedulePosts ? allowManagerToSchedulePosts(manager.uid) :preventManagerToSchedulePosts(manager.uid)"
                          type="primary"
                          size="sm"
                          class="button small-button secondary-button"
                        >
                          {{ manager.cannotSchedulePosts ? 'Allow scheduling post' : 'Prevent scheduling posts' }}
                        </base-button>
                      </tooltip>
                      <base-button
                        @click="removeManager(manager.uid)"
                        type="primary"
                        size="sm"
                        class="button small-button transparent-button"
                        :loading="removingManagers.includes(manager.uid)"
                      >
                        Remove
                      </base-button>
                    </div>
                  </li>
                </ul>
              </div>
              <new-button
                @click="createManagerInvitationToken"
              >
                <div class="flex items-center gap-2">
                  <span>
                    Add
                    <span v-if="userProfile.managers.length > 0">another</span>
                    <span v-else>a</span>
                    manager
                  </span>
                </div>
              </new-button>
              <div v-if="managerInvitationTokens.length > 0">
                <div
                  v-for="{ token, url, expiresAt } in managerInvitationTokens"
                  v-bind:key="token"
                  class="space-y-2"
                >
                  <div class="flex space-x-2">
                    <base-input :value="url" disabled />
                    <base-button
                      @click="$copyText(url);$notify({ type: 'success', message: 'Copied!' });"
                      size="sm"
                      class="button small-button secondary-button"
                    >
                      Copy
                    </base-button>
                    <base-button
                      @click="deleteManagerInvitationToken(token)"
                      size="sm"
                      class="button small-button secondary-button"
                      :loading="deletingManagerInvitationTokens.includes(token)"
                    >
                      Delete
                    </base-button>
                  </div>
                  <p class="text-secondary flex flex-row items-center">
                    <inline-svg src="/img/icons/info.svg"></inline-svg>&nbsp;
                    This link is valid till {{ expiresAt }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab == 2" class="space-y-8">
          <div class="app-settings-section border space-y-4 border-gray-90 p-6 rounded-2xl">
            <h3>Connections</h3>
            <div class="space-y-6">
              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex col-span-2 sm:col-span-1 items-center gap-4">
                  <span
                  class="w-8 h-8 rounded-full flex items-center justify-center "
                  :class="[isUserConnectedToFacebook ? 'bg-main-color-100'  : 'bg-gray-100']"
                  >
                    <inline-svg src="/img/icons/new-facebook.svg" class="fill-white" />
                  </span>
                  Facebook
                </span>
                <span v-if="isUserConnectedToFacebook" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <button
                  v-if="isUserConnectedToFacebook && isFacebookAccountValid"
                  class="button small-button secondary-danger"
                  @click="disconnectFacebook()"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectFacebook()"
                >
                  {{ !selectedFacebookPageId && facebookPages.length > 0 ? 'Change account' : 'Connect' }}
                </button>
              </div>

                <p class="text-base pb-2">{{ facebookStatusText }}</p>
                <div v-if="!selectedFacebookPageId && facebookPages.length > 0" class="flex flex-wrap">
                  <div
                    class="custom-tags max-w-full overflow-hidden"
                    v-for="facebookPage in facebookPages"
                    :key="facebookPage.id"
                  >
                    <label class="facebook-page" @click="selectFacebookPage(facebookPage.id, facebookPage.name)">
                      <span :title="facebookPage.name">{{ facebookPage.name }}</span>
                    </label>
                  </div>
                </div>
              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                  <span class="w-8 h-8 rounded-full flex items-center justify-center"
                  :class="[ isConnectedToGumroad ? 'bg-gumroad-100': ' bg-gray-100' ]">
                    <inline-svg src="/img/icons/new-gumroad.svg" />
                  </span>
                  Gumroad
                </span>
                <span v-if="isConnectedToGumroad" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <button
                  v-if="isConnectedToGumroad"
                  class="button small-button secondary-danger justify-self-end"
                  @click="disconnectGumroad()"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectGumroad()"
                >
                  Connect
                </button>
              </div>
              <p class="text-secondary text-base pb-2">{{ gumroadStatusText }}</p>
              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                  <span class="w-8 h-8 rounded-full flex items-center justify-center"
                  :class="[isUserConnectedToLinkedIn ? 'bg-linkedin-100' : 'bg-gray-100']"
                  >
                    <inline-svg src="/img/icons/new-linkedin.svg" class="fill-white" />
                  </span>
                  LinkedIn
                </span>
                <span v-if="isUserConnectedToLinkedIn" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <button
                  v-if="isUserConnectedToLinkedIn"
                  class="button small-button secondary-danger justify-self-end"
                  @click="disconnectLinkedIn()"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectLinkedIn()"
                >
                  Connect
                </button>
              </div>
              <base-alert
                type="warning"
                radius="rounded-lg"
                class="my-4"
                v-if="isUserConnectedToLinkedIn && userProfile.integrations.linkedin.id && !userProfile.integrations.linkedin.idsToPostAs"
              >
                Please disconnect and then reconnect your LinkedIn account to Hypefury in order to update the permissions.
              </base-alert>
              <p class="text-secondary text-base">{{ linkedInStatusText }}</p>
              <div class="space-y-3">
                <tooltip
                  v-if="isUserConnectedToLinkedIn && (linkedInTokenInfo && !linkedInTokenInfo.isExpired && linkedInTokenInfo.expiresInDays <= 7)"
                  :content="`Your credentials ${
                    linkedInTokenInfo.isExpired
                      ? 'have expired.'
                      : linkedInTokenInfo.expiresInDays <= 7
                      ? `expire in ${linkedInTokenInfo.expiresInDays} days.`
                      : ''
                  } Click here to refresh them.`"
                >
                  <base-button
                    @click="connectLinkedIn(true)"
                    type="primary"
                    size="sm"
                    class="button small-button primary-button"
                    >Refresh credentials
                  </base-button>
                </tooltip>
                <div
                  v-if="isUserConnectedToLinkedIn && linkedInTokenInfo && linkedInTokenInfo.isExpired"
                  class="flex flex-row text-red-600 items-center"
                >
                  <inline-svg src="/img/icons/warning.svg"/>
                  <p
                    class="text-primary ml-1"
                  >
                    Your LinkedIn credentials have expired, please re-authenticate again.
                  </p>
                </div>
                <div class="flex space-x-4" v-if="isUserConnectedToLinkedIn">
                  <div class="flex items-center">
                    <p class="text-secondary text-base">LinkedIn authentication expiration reminder</p>
                    <info-tooltip
                      content="If enabled you will receive by email a reminder to refresh your LinkedIn credentials if it's about to expire in 7 days or less."
                    ></info-tooltip>
                  </div>
                  <div class="toggler">
                  <input
                    type="checkbox"
                    name="linked-in-expiration-reminder"
                    id="linked-in-expiration-reminder"
                    v-model="shouldSendLinkedInExpirationReminder"
                  />
                    <label for="linked-in-expiration-reminder"></label>
                  </div>
                </div>
              </div>
              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                  <span class="w-8 h-8 rounded-full flex items-center justify-center"
                  :class="[isUserConnectedToInstagram ? 'bg-instagram-100' : 'bg-gray-100']">
                    <inline-svg src="/img/icons/new-instagram.svg" class="fill-white" />
                  </span>
                  Instagram
                </span>
                <span v-if="isUserConnectedToInstagram" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <button
                  v-if="isUserConnectedToInstagram"
                  class="button small-button secondary-danger justify-self-end"
                  @click="disconnectInstagram()"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectInstagram()"
                >
                  Connect
                </button>
                <template v-if="isUserConnectedToInstagram && doesUserHaveInstagramPages">
                  <div class="flex flex-wrap gap-3 items-center col-span-3">
                    <div
                        class="custom-tags max-w-full overflow-hidden"
                        v-for="page in instagramPages" :key="page.id"
                    >
                    <label @click="updateInstagramPage(page.id)">
                      <span :title="page.name">{{ page.name }}</span>
                    </label>
                  </div>
                  </div>
                </template>
              </div>
              <div>
                <p
                  class="text-secondary text-base pb-2"
                  v-if="isUserConnectedToInstagram"
                >
                  Currently connected account is: {{ userProfile.integrations.instagram.tokenInfo.name }}
                </p>
                <div
                  class="flex items-center text-secondary text-base pb-2"
                  v-else
                >
                  <span>Connect your Instagram account to Hypefury.</span>
                  <info-tooltip
                    content="This will only work for Instagram Business Accounts (not Creator/Personal)."
                    footer="<a href='https://hypefury.crisp.help/en/article/how-do-i-connect-my-instagram-account-to-hypefury-1s1xz7a/' target='_blank'>Learn more</a>"
                  ></info-tooltip>
                </div>
              </div>

              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                  <span class="w-8 h-8 rounded-full flex items-center justify-center"
                  :class="[isUserConnectedToYouTubeWithChannel ? 'text-youtube-bg' : 'text-gray-100']">
                    <inline-svg src="/img/icons/new-youtube.svg" class="fill-current" />
                  </span>
                  YouTube
                </span>

                <span v-if="isUserConnectedToYouTubeWithChannel" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <div
                  class="custom-tags max-w-full overflow-hidden"
                  v-for="youtubeChannel in youtubeChannels"
                  :key="youtubeChannel.id"
                >
                  <label class="youtube-channel-selector" @click="selectYoutubeChannel(youtubeChannel.id, youtubeChannel.name)">
                    <span :title="youtubeChannels.name">{{ youtubeChannel.name }}</span>
                  </label>
                </div>

                <button
                  v-if="isUserConnectedToYouTubeWithChannel"
                  class="button small-button secondary-danger justify-self-end"
                  @click="disconnectYouTube()"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectYouTube()"
                >
                  Connect
                </button>
              </div>
              <div>
                <p
                  class="text-secondary text-base pb-2"
                  v-if="isUserConnectedToYouTubeWithChannel"
                >
                  {{ youtubeStatusText }}
                </p>
              </div>

              <template>
                <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                  <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                    <span class="w-8 h-8 rounded-full flex items-center justify-center"
                    :class="[ isUserConnectedToThreads ? 'bg-black': ' bg-gray-100' ]">
                      <inline-svg src="/img/icons/new/socials/threads.svg" class="text-white" />
                    </span>
                    Threads
                  </span>
                  <span v-if="isUserConnectedToThreads" class="text-lg flex-1">Connected</span>
                  <span v-else class="text-lg flex-1">Not connected</span>
                  <button
                    v-if="isUserConnectedToThreads"
                    class="button small-button secondary-danger"
                    @click="disconnectThreads"
                    >
                    Disconnect
                  </button>
                  <button
                    v-else
                    class="button small-button secondary-button"
                    @click="connectThreads"
                    >
                    Connect
                  </button>
                </div>
                <p class="text-secondary text-base pb-2">{{ isUserConnectedToThreads ? `Currently connected account is:
                  ${userProfile.integrations.threads.tokenInfo.username}` : 'Connect your Threads account to Hypefury.' }}</p>
              </template>

              <div class="grid sm:grid-cols-3 grid-cols-2 gap-y-4 items-center">
                <span class="text-lg flex-1 flex items-center col-span-2 sm:col-span-1 gap-4">
                  <span class="w-8 h-8 rounded-full flex items-center justify-center"
                  :class="[ isUserConnectedToTiktok ? 'bg-black': ' bg-gray-100' ]">
                    <inline-svg src="/img/icons/new/socials/tiktok.svg" class="text-white" />
                  </span>
                  TikTok
                </span>
                <span v-if="isUserConnectedToTiktok" class="text-lg flex-1">Connected</span>
                <span v-else class="text-lg flex-1">Not connected</span>
                <button
                  v-if="isUserConnectedToTiktok"
                  class="button small-button secondary-danger"
                  @click="disconnectTikTok"
                >
                  Disconnect
                </button>
                <button
                  v-else
                  class="button small-button secondary-button"
                  @click="connectTikTok"
                >
                  Connect
                </button>
              </div>
              <p class="text-secondary text-base pb-2">{{ tiktokStatusText }}</p>
            </div>
          </div>
          <div ref="#external-apps">
            <external-apps />
          </div>
        </div>
        <div v-if="activeTab == 3" class="space-y-8">
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl">
            <h3 ref="#composer">Composer</h3>
            <div class="grid gap-6">
              <div class="grid gap-4">
                <div class="flex items-center gap-2">
                  <div class="toggler" data-cy="auto-rt-switch">
                    <input
                      type="checkbox"
                      name="autoRT"
                      id="autoRT"
                      v-model="autoRT"
                    />
                    <label for="autoRT"></label>
                  </div>
                  <span class="text-lg">Empty slots act as evergreen slots</span>
                </div>
                <p class="text-gray-20 text-base">Keep your timeline active even when you’re busy or away. When this setting is enabled, Hypefury will retweet a random evergreen post whenever a slot of your schedule is empty.</p>
              </div>
              <div class="space-y-4">
                <div class="flex items-center gap-2">
                  <div class="toggler" data-cy="split-to-thread-switch">
                    <input
                      type="checkbox"
                      name="shouldSplitLongText"
                      id="shouldSplitLongText"
                      v-model="shouldSplitLongText"
                    />
                    <label for="shouldSplitLongText"></label>
                  </div>
                  <span class="text-lg">Long text splitting into a thread</span>
                </div>
                <p class="text-gray-20 text-base">Disable this to prevent Hypefury from splitting long texts into a thread when you paste them into the tweet composer.</p>
              </div>
              <div class="space-y-4">
                <div class="flex items-center gap-2">
                  <div class="toggler" data-cy="expand-thread-switch">
                    <input
                      type="checkbox"
                      name="createTweetOnEnterPressEnabled"
                      id="createTweetOnEnterPress"
                      v-model="createTweetOnEnterPressEnabled"
                    />
                    <label for="createTweetOnEnterPress"></label>
                  </div>
                  <span class="text-lg">Expand thread after three line breaks</span>
                </div>
                <p class="text-gray-20 text-base">
                  Disable this to prevent Hypefury from creating a new tweet in the thread when you press Enter three times in a row in the tweet composer.
                </p>
              </div>
              <div class="grid gap-4">
                <div class="flex items-center gap-2">
                  <div class="toggler" data-cy="auto-fill-empty-composer-switch">
                    <input
                      type="checkbox"
                      name="shouldAutoFillEmptyComposer"
                      id="shouldAutoFillEmptyComposer"
                      v-model="shouldAutoFillEmptyComposer"
                    />
                    <label for="shouldAutoFillEmptyComposer"></label>
                  </div>
                  <span class="text-lg">Auto-fill empty composer with your best tweets</span>
                </div>
                <p class="text-gray-20 text-base">Draw inspiration from your best tweets when you open the composer.</p>
              </div>
              <!-- <div class="grid gap-4">
                <div class="flex items-center gap-2">
                  <div class="toggler" data-cy="thread-finisher-switch">
                    <input
                      type="checkbox"
                      name="isThreadFinisherEnabled"
                      id="isThreadFinisherEnabled"
                      v-model="isThreadFinisherEnabled"
                    />
                    <label for="isThreadFinisherEnabled"></label>
                  </div>
                  <span class="text-lg">Thread finisher</span>
                </div>
                <p class="text-gray-20 text-lg">If enabled, activates thread finishers.</p>
              </div> -->
            </div>
          </div>
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl">
            <h3>Tweetshots Customization</h3>
            <div class="grid gap-6">
              <div class="relative mb-2 overflow-hidden rounded-md">
                <div class="overlay" v-if="tweetshotSettings.isLoadingTweetshot">
                  <div class="loader">
                    <span class="loading loading-lg"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
                  </div>
                </div>
                <img
                  :src="tweetshotPreviewURL"
                  class="img-thumbnail"
                  @error="displayPlaceHolderImage"
                  @load="tweetshotSettings.isLoadingTweetshot = false">
              </div>
              <div>
                <p class="text-lg mb-6">Choose a layout</p>
                <div
                  class="overflow-y-scroll h-56 p-2 grid md:grid-cols-5 grid-cols-4 md:gap-4 gap-2 app-settings-slot rounded-lg"
                >
                  <button
                    @click="setTweetshotLayout(tweetshotLayout.value)"
                    v-for="tweetshotLayout in tweetshotSettings.tweetshotLayouts"
                    :key="tweetshotLayout.value"
                    class="rounded-lg flex items-center justify-center md:py-6 py-2 px-0.5 md:px-0 cursor-pointer post-ig-theme outline-none focus:outline-none"
                    :class="[
                      tweetshotSettings.tweetshotLayout == tweetshotLayout.value
                        ? 'border-2 border-main-color-100'
                        : 'border border-gray-90 dark-mode-border-60',
                    ]"
                  >
                    <span class="md:text-lg text-xs">
                      {{ tweetshotLayout.name }}
                      <br />
                      <span class="text-xs">
                        {{ tweetshotLayout.includesMedia ? '(Includes media)' : '' }}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div>
                <p class="text-lg mb-6">Choose a theme</p>
                <div class="md:flex-no-wrap flex flex-wrap gap-x-1 gap-y-2">
                  <tooltip
                    :content="tweetshotTheme.name"
                    :delayOnMouseOver="500"
                    v-for="tweetshotTheme in tweetshotSettings.tweetshotThemes"
                    v-bind:key="tweetshotTheme.value"
                  >
                    <button
                      @click="setTweetshotTheme(tweetshotTheme.value)"
                      class="btn transparent border-2 border-gray-10 rounded-md flex items-center justify-center w-7.5"
                      :class="[ { 'border-2 border-main-color-100': tweetshotSettings.tweetshotTheme == tweetshotTheme.value } ]"
                    >
                      <img :src="`./tweetshot/themes/new-${tweetshotTheme.value}.png`" class="h-auto w-full" />
                    </button>
                  </tooltip>
                </div>
              </div>
              <div class="flex justify-end space-x-4">
                <base-button
                  @click="saveTweetshotSettings()"
                  v-if="tweetshotSettings.canSaveTweetshotSettings"
                  :disabled="tweetshotSettings.isSavingTweetshotSettings"
                  class="button small-button primary-button">
                  <div class="flex space-x-2 items-center">
                    <inline-svg
                      v-if="this.userProfile.customerStatus === 'none'"
                      src="/img/icons/premium.svg"
                    />
                    <span>{{ tweetshotSettings.isSavingTweetshotSettings ? 'Saving...' :'Save' }}</span>
                  </div>
                </base-button>
                <base-button
                  v-if="tweetshotSettings.canSaveTweetshotSettings"
                  type="button"
                  class="button small-button secondary-button"
                  @click="resetTweetshotPreview()"
                >
                  Cancel
                </base-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab == 4" class="space-y-8">
          <div class="app-settings-section space-y-4 border border-gray-90 p-6 rounded-2xl">
            <div class="flex items-center">
              <h3>Notifications</h3>
              <info-tooltip
                :content="`This feature is limited to ${getPlanLabel('standard')} plan and above users.`"
              ></info-tooltip>
            </div>
            <div class="space-y-4">
              <div class="flex items-center gap-2">
                <div class="toggler" data-cy="when-post-fails-switch">
                  <input type="checkbox" id="isPublishFailureEmailEnabled" v-model="isPublishFailureEmailEnabled" />
                  <label for="isPublishFailureEmailEnabled"></label>
                </div>
                <span class="text-lg">When a post fails to be published</span>
              </div>
              <div class="flex items-center gap-2">
                <div class="toggler" data-cy="weekly-digest-switch">
                  <input type="checkbox" id="isWeeklyDigestEnabled" v-model="isWeeklyDigestEnabled" />
                  <label for="isWeeklyDigestEnabled"></label>
                </div>
                <span class="text-lg">Weekly digest</span>
              </div>
              <div class="flex items-center gap-2">
                <div class="toggler" data-cy="daily-digest-switch">
                  <input type="checkbox" id="isDailyDigestEnabled" v-model="isDailyDigestEnabled" />
                  <label for="isDailyDigestEnabled"></label>
                </div>
                <span class="text-lg">Daily digest</span>
              </div>
              <div class="flex items-center gap-2">
                <div class="toggler" data-cy="empty-queue-switch">
                  <input type="checkbox" id="isEmptyQueueEmailEnabled" v-model="isEmptyQueueEmailEnabled" />
                  <label for="isEmptyQueueEmailEnabled"></label>
                </div>
                <span class="text-lg">When your queue becomes empty</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isAddUser" class="fixed inset-0 z-50 flex items-center justify-center">
          <div class="p-8 rounded-3xl bg-white dark-mode:bg-dark-mode-70 w-466 relative z-10">
            <div class="grid gap-6">
              <div class="grid gap-2">
                <div class="flex items-center gap-4">
                  <div class="w-12 h-12 rounded-full bg-main-color-100 flex items-center justify-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.19C0 17 1.29 18.93 3.56 19.66C4.22 19.89 4.98 20 5.81 20H14.19C15.02 20 15.78 19.89 16.44 19.66C18.71 18.93 20 17 20 14.19V5.81C20 2.17 17.83 0 14.19 0ZM18.5 14.19C18.5 16.33 17.66 17.68 15.97 18.24C15 16.33 12.7 14.97 10 14.97C7.3 14.97 5.01 16.32 4.03 18.24H4.02C2.35 17.7 1.5 16.34 1.5 14.2V5.81C1.5 2.99 2.99 1.5 5.81 1.5H14.19C17.01 1.5 18.5 2.99 18.5 5.81V14.19Z" fill="white"/>
                      <path d="M9.99992 6C8.01992 6 6.41992 7.6 6.41992 9.58C6.41992 11.56 8.01992 13.17 9.99992 13.17C11.9799 13.17 13.5799 11.56 13.5799 9.58C13.5799 7.6 11.9799 6 9.99992 6Z" fill="white"/>
                    </svg>
                  </div>
                  <span class="text-2xl font-bold dark-mode:text-white">Add user</span>
                </div>
              </div>
              <div>
                <label for="email" class="block text-black font-medium mb-2">Email</label>
                <input type="text" placeholder="Type email here" class="form-field" />
              </div>
              <div>
                <label for="user-type" class="block text-black font-medium mb-2">User type</label>
                <div class="relative">
                  <div class="relative">
                    <div class="form-field flex items-center cursor-pointer" @click="userType = !userType">
                      <span>Admin</span>
                      <span class="absolute right-0 pr-4">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1.25L4.5 4.75L8 1.25" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div v-if="userType" class="absolute top-0 p-8 bg-white rounded-2xl w-full mt-16 grid gap-8" style="box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08)">
                    <div class="cursor-pointer text-main-color-100 font-medium">
                      <span class="text-lg block mb-1 leading-none">Admin</span>
                      <span class="text-sm leading-none">Complete account access</span>
                    </div>
                    <div class="cursor-pointer font-medium">
                      <span class="text-lg block mb-1 leading-none">Contributor</span>
                      <span class="text-sm leading-none">Can create and publish tweets</span>
                    </div>
                    <div class="cursor-pointer font-medium">
                      <span class="text-lg block mb-1 leading-none">Viewer</span>
                      <span class="text-sm leading-none">Can only view content and schedule</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between">
                <button class="custom-button bg-main-color-100">Send invite</button>
              </div>
            </div>
          </div>
          <div @click="isAddUser = false" class="fixed inset-0 cursor-pointer bg-gray-10 opacity-50" />
        </div>
        <div v-if="isEditUser" class="fixed inset-0 z-50 flex items-center justify-center">
          <div class="p-8 rounded-3xl bg-white dark-mode:bg-dark-mode-70 w-466 relative z-10">
            <div class="grid gap-6">
              <div class="grid gap-2">
                <div class="flex items-start gap-4">
                  <div class="w-12 h-12 rounded-full bg-main-color-100 flex items-center justify-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.19C0 17 1.29 18.93 3.56 19.66C4.22 19.89 4.98 20 5.81 20H14.19C15.02 20 15.78 19.89 16.44 19.66C18.71 18.93 20 17 20 14.19V5.81C20 2.17 17.83 0 14.19 0ZM18.5 14.19C18.5 16.33 17.66 17.68 15.97 18.24C15 16.33 12.7 14.97 10 14.97C7.3 14.97 5.01 16.32 4.03 18.24H4.02C2.35 17.7 1.5 16.34 1.5 14.2V5.81C1.5 2.99 2.99 1.5 5.81 1.5H14.19C17.01 1.5 18.5 2.99 18.5 5.81V14.19Z" fill="white"/>
                      <path d="M9.99992 6C8.01992 6 6.41992 7.6 6.41992 9.58C6.41992 11.56 8.01992 13.17 9.99992 13.17C11.9799 13.17 13.5799 11.56 13.5799 9.58C13.5799 7.6 11.9799 6 9.99992 6Z" fill="white"/>
                    </svg>
                  </div>
                  <div>
                    <span class="text-2xl font-bold dark-mode:text-white block">Samy Dindane</span>
                    <span class="text-lg text-gray-80">samy@hypefury.com</span>
                  </div>
                </div>
              </div>
              <div>
                <label for="user-type" class="block text-black font-medium mb-2">User type</label>
                <div class="relative">
                  <div class="relative">
                    <div class="form-field flex items-center cursor-pointer" @click="userType = !userType">
                      <span>Admin</span>
                      <span class="absolute right-0 pr-4">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1.25L4.5 4.75L8 1.25" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div v-if="userType" class="absolute top-0 p-8 bg-white rounded-2xl w-full mt-16 grid gap-8" style="box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08)">
                    <div class="cursor-pointer text-main-color-100 font-medium">
                      <span class="text-lg block mb-1 leading-none">Admin</span>
                      <span class="text-sm leading-none">Complete account access</span>
                    </div>
                    <div class="cursor-pointer font-medium">
                      <span class="text-lg block mb-1 leading-none">Contributor</span>
                      <span class="text-sm leading-none">Can create and publish tweets</span>
                    </div>
                    <div class="cursor-pointer font-medium">
                      <span class="text-lg block mb-1 leading-none">Viewer</span>
                      <span class="text-sm leading-none">Can only view content and schedule</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-danger-100 text-lg font-medium cursor-pointer">Remove user</span>
              <div class="flex items-center justify-between">
                <button class="custom-button bg-main-color-100">Save</button>
              </div>
            </div>
          </div>
          <div @click="isEditUser = false" class="fixed inset-0 cursor-pointer bg-gray-10 opacity-50" />
        </div>
        <upgrade-to-premium-pop-up
          :show="showUpgradeToPremiumPopUp"
          :content="upgradeToPremiumPopUpContent"
          :contentForNonSubscribers="upgradeToPremiumPopUpContent"
          @close="showUpgradeToPremiumPopUp = false" />
        <delete-account-modal
          @close="closeDeleteAccountModal"
          :show="isDeleteAccountModalVisible" />
      </div>
    </div>
  </div>
</template>

<script>
  import * as config from '@/config';
  import ExternalApps from '@/components/ExternalApps'
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import 'moment/min/locales';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import DeleteAccountModal from '@/views/Modals/DeleteAccountModal.vue';
  import dao from '@/dao';
  import controller from '@/controller';
  import { removeQueryParamFromURL } from '../util/removeQueryParamFromURL';
  import { store } from '@/store';
  import BetaFeaturesMixin from './Mixins/BetaFeaturesMixin.vue';
  import uuidTime from 'uuid-time';
  const fb = require('@/firebase');

  import axios from 'axios';
  import Vue from 'vue';
  import VueAuthenticate from 'vue-authenticate';
  import VueAxios from 'vue-axios';
  import LinkedInMixin from './Mixins/LinkedInMixin.vue';
  import FacebookMixin from './Mixins/FacebookMixin.vue';
  import VueSelect from '@/components/VueSelect.vue';
  import InstagramMixin from './Mixins/InstagramMixin.vue';
  import ThreadsMixin from './Mixins/ThreadsMixin.vue';
  import TweetshotSettingsMixin from '@/views/Mixins/TweetshotSettingsMixin.vue';
  import MobileMixin from './Mixins/MobileMixin';
  import LogoutMixin from './Mixins/LogoutMixin';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import YouTubeMixin from './Mixins/YouTubeMixin.vue';
  import TiktokMixin from './Mixins/TiktokMixin.vue';

  Vue.use(VueAuthenticate, {
    bindRequestInterceptor: function () {
      this.$http.interceptors.request.use((config) => {
        if (config.url.includes('auth')) {
          config.headers['Authorization'] = [this.options.tokenType, this.getToken()].join(' ');
        } else {
          // Do nothing (default behavior of vue-authentication: remove the Authorization header)
        }
        return config;
      });
    },
    baseUrl: `${config.httpRoutesURL}/integrations/`,
    providers: {
      gumroad: {
        authorizationEndpoint: 'https://gumroad.com/oauth/authorize',
        clientId: config.gumroadApplicationId,
        display: 'popup',
        name: 'oauth2',
        oauthType: '2.0',
        redirectUri: config.redirectURI,
        url: `${config.httpRoutesURL}/integrations/auth/gumroad`,
        requiredUrlParams: ['scope'],
        scope: ['view_sales', 'edit_products'],
        scopeDelimiter: ' ',
        tokenPath: 'accessToken',
      },
      instagram: {
        name: 'instagram',
        url: `${config.httpRoutesURL}/integrations/auth/instagram`,
        clientId: config.instagramApplicationId,
        authorizationEndpoint: 'https://graph.facebook.com/v20.0/oauth/authorize',
        redirectUri: config.redirectURI,
        responseType: 'token',
        requiredUrlParams: ['scope'],
        scope: [
          'instagram_basic',
          'instagram_content_publish',
          'pages_read_engagement',
          'business_management',
        ],
        scopeDelimiter: ' ',
        oauthType: '2.0',
        popupOptions: { width: 600, height: 800 }
      },
      linkedin: {
        authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/authorization',
        clientId: config.linkedInApplicationId,
        display: 'popup',
        name: 'oauth2',
        oauthType: '2.0',
        redirectUri: config.redirectURI,
        requiredUrlParams: ['scope'],
        scope: [
          'w_organization_social',
          'r_organization_social',
          'rw_organization_admin',
          'w_member_social',
          'r_emailaddress',
          'r_basicprofile',
          'r_liteprofile',
        ],
        state: 'STATE',
        popupOptions: { width: 527, height: 582 },
        scopeDelimiter: ' ',
        tokenPath: 'accessToken',
      },
      facebook: {
        authorizationEndpoint: 'https://www.facebook.com/v20.0/dialog/oauth',
        clientId: config.facebookApplicationId,
        responseType: 'token',
        redirectUri: config.redirectURI,
        oauthType: '2.0',
        requiredUrlParams: ['display', 'scope'],
        scope: [
          'email',
          'business_management',
          'pages_show_list',
          'pages_manage_posts',
          'pages_read_engagement',
        ],
        display: 'popup',
        scopeDelimiter: ',',
        popupOptions: { width: 580, height: 400 }
      },
      google: {
        scope: ['https://www.googleapis.com/auth/youtube', 'https://www.googleapis.com/auth/youtube.readonly', 'https://www.googleapis.com/auth/youtube.force-ssl'],
        clientId: config.youtubeApplicationId,
        name: 'google',
        url: '/auth/google',
        authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
        redirectUri: config.redirectURI,
        requiredUrlParams: ['scope', 'access_type', 'offline'],
        accessType: 'offline',
        optionalUrlParams: ['display'],
        scopeDelimiter: ' ',
        display: 'popup',
        oauthType: '2.0',
        popupOptions: { width: 452, height: 633 },
        tokenPath: 'accessToken',
      },
      threads: {
        url: `${config.httpRoutesURL}/integrations/auth/threads`,
        authorizationEndpoint: 'https://threads.net/oauth/authorize',
        clientId: config.threadsApplicationId,
        responseType: 'code',
        redirectUri: config.redirectURI,
        oauthType: '2.0',
        requiredUrlParams: ['scope'],
        scope: [
          'threads_basic',
          'threads_content_publish',
          'threads_manage_replies',
        ],
        display: 'popup',
        scopeDelimiter: ',',
        popupOptions: { width: 580, height: 400 },
        tokenPath: 'accessToken',
        name: 'threads',
      },
      tiktok: {
        authorizationEndpoint: 'https://www.tiktok.com/v2/auth/authorize/',
        clientKey: config.tiktokClientKey,
        oauthType: '2.0',
        url: `${config.httpRoutesURL}/integrations/auth/tiktok`,
        defaultUrlParams: ['response_type', 'client_key', 'redirect_uri', 'scope'],
        redirectUri: config.redirectURI,
        scope: ['user.info.basic,video.upload,video.publish'],
        tokenPath: 'accessToken',
        response_type: 'code',
      },
    }
  });
  Vue.use(VueAxios, axios);

  export default {
    name: "settings",
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile', parentUserProfile: 'getParentUserProfile' }),
      ...mapState([ 'userProfiles', 'isUserManaging' ]),
      isAllowedToDeleteAccount() {
        return lodash.get(this.parentUserProfile, 'uid') === this.userProfile.uid;
      },
      gumroadStatusText() {
        if (!this.isConnectedToGumroad) {
          return 'Connect your Gumroad merchant account to Hypefury.';
        } else {
          let text = 'You are connected to Gumroad';
          const name = this.userProfile.integrations.gumroad.name;
          if (name) text += ` as ${name}`
          return text + '.';
        }
      },
      linkedInStatusText() {
        if (!this.isUserConnectedToLinkedIn) {
          return 'Connect your LinkedIn account to Hypefury.';
        } else {
          let text = 'You are connected to LinkedIn';
          const name = this.userProfile.integrations.linkedin.name;
          if (name) text += ` as ${name}`
          return text + '.';
        }
      },
      facebookStatusText() {
        if (!this.isUserConnectedToFacebook) {
          return 'Connect your Facebook page to Hypefury.';
        } else {
          const userName = this.userProfile.integrations.facebook.name;
          const page = this.userProfile.integrations.facebook.page;
          const needsToChooseOneFacebookpage =
            this.facebookPages.length > 0 && !this.selectedFacebookPageId;

          if (page && page.connected === null && this.selectedFacebookPageId) {
            return 'Loading...';
          }

          let text = 'You are connected to Facebook';
          if (userName) text += ` as ${userName}`;

          if (page && !page.connected) {
            text += '. But your account does not own any Facebook page';
          }

          if (needsToChooseOneFacebookpage) {
            text +=
              '. But you have given us permission to manage more than one page, please choose one from the list';
          }

          if (page && page.name) {
            text += ` to manage the ${page.name} page`;
          }
          return text + '.';
        }
      },
      otherConnectedUserProfiles() {
        const managedAccounts = lodash.get(this.userProfile, 'managedAccounts');
        const managedAccountUserIds = managedAccounts.map((user) => user.uid);
        const additionalAccountIds = lodash.get(this.userProfile, 'additionalAccountIds', []);

        return Object.values(this.userProfiles).filter((profile) => {
          return (
            profile.uid !== this.userProfile.uid &&
            !managedAccountUserIds.includes(profile.uid) &&
            additionalAccountIds.some((account) => account.id === profile.uid)
          );
        });
      },
      allTimeZones() {
        return moment.tz.names().map(timezone => timezone.replace(/_/g, ' '));
      },
      managerInvitationTokens() {
        const browserLocale = require('browser-locale')();
        moment.locale(browserLocale);
        return this.userProfile.managerInvitationTokens.map((token) => {
          return {
            token: token,
            url: config.appURL + '/settings?managerInvitationToken=' + token, // todo: use url builder
            expiresAt: moment(uuidTime.v1(token)).add(1, 'days').format('LT L'),
          };
        })
      },
      doesUserHaveInstagramPages(){
        return lodash.get(this.userProfile, 'integrations.instagram.pages.length', 0) > 0;
      },
      instagramPages() {
        return lodash.get(this.userProfile, 'integrations.instagram.pages', []).filter(page => page.id);
      },
      tiktokStatusText() {
        if (!this.isUserConnectedToTiktok) {
          return 'Connect your TikTok account to Hypefury.';
        } else {
          let text = 'You are connected to TikTok';
          const name = this.userProfile.integrations.tiktok.userInfo.creator_username;
          if (name) text += ` as ${name}`
          return text + '.';
        }
      },
  },
  data() {
      return {
        activeTab: 0,
        isAddUser: false,
        isEditUser: false,
        userType: false,
        inspirationTweetsType: null,
        autoRT: null,
        createTweetOnEnterPressEnabled: null,
        isPublishFailureEmailEnabled: null,
        isWeeklyDigestEnabled: null,
        isDailyDigestEnabled: null,
        isEmptyQueueEmailEnabled: null,
        didUserJustConnectInstagram: false,
        isConnectedToGumroad: null,
        isConnectingInstagram: false,
        premiumFeatureOnHold: '',
        showUpgradeToPremiumPopUp: false,
        shouldAutoFillEmptyComposer: null,
        shouldSplitLongText: null,
        timezone: null,
        upgradeToPremiumPopUpContent: '',
        theme: null,
        removingManagers: [],
        removingManagedAccounts: [],
        deletingManagerInvitationTokens: [],
        isAddingManager: false,
        isDeleteAccountModalVisible: false,
        ghostwriterName: '',
        isGhostwriter: false,
        showGhostwriterNamePrompt: false,
        showInspirationTweetsInComposer: true,
        isThreadFinisherEnabled: null,
        shouldSendLinkedInExpirationReminder: null,
        isDeletingAccount: false,
        areFacebookSettingsVisible: false,
        isGumroadInfoVisible: false,
        youtubeChannels: null,
        isConnectedToTikTok: false,
      };
    },
    watch: {
      'userProfile.integrations.facebook.page'(page, oldPage) {
        if (!lodash.get(oldPage, 'connected') && lodash.get(page, 'connected')) {
          this.$notify({
            message: 'Your Facebook page has successfully been connected to Hypefury!',
          });
        }
      },
      autoRT(val, old) {
        if (old === null) return;
        fb.usersCollection
          .doc(this.userProfile.uid)
          .update({ autoRT: val })
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling the auto RT feature.`,
              type: 'warning',
            });
          });
      },
      isPublishFailureEmailEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.emailCampaignsSettings.togglePublishFailureEmail(this.userProfile, val)
          .then(() => this.$notify({'type': 'success', 'message': 'Publish failure email settings updated!'}))
          .catch(error => {
            this.$notify({'type': 'error', 'message': 'An error has occurred while toggling your email preference.'});
          });
      },
      isWeeklyDigestEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.emailCampaignsSettings.toggleWeeklyDigests(this.userProfile, val)
          .then(() => this.$notify({'type': 'success', 'message': 'Weekly email digest settings updated!'}))
          .catch(error => {
            this.$notify({'type': 'error', 'message': 'An error has occurred while toggling your email preference.'});
          });
      },
      isDailyDigestEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.emailCampaignsSettings.toggleDailyDigests(this.userProfile, val)
          .then(() => this.$notify({'type': 'success', 'message': 'Daily email digest settings updated!'}))
          .catch(error => {
            this.$notify({'type': 'error', 'message': 'An error has occurred while toggling your email preference.'});
          });
      },
      isEmptyQueueEmailEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.emailCampaignsSettings.toggleEmptyQueueEmails(this.userProfile, val)
          .then(() => this.$notify({'type': 'success', 'message': 'Empty Queue email alert updated!'}))
          .catch(error => {
            this.$notify({'type': 'error', 'message': 'An error has occurred while toggling your email preference.'});
          });
      },
      inspirationTweetsType(val, old) {
        if (old === null) return;
        dao.userProfile.updateInspirationTweetsType(this.userProfile.uid, val).then(() => {
          this.$notify({'type': 'success', 'message': 'Inspiration tweets settings saved!'});
        })
      },
      theme(val) {
        firebase.firestore().collection('users')
          .doc(this.userProfile.uid)
          .update({'settings.theme': val});
      },
      shouldAutoFillEmptyComposer(val, old) {
        if (old === null) return;
        dao.userProfile.updateShouldAutoFillEmptyComposer(this.userProfile.uid, val)
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          });
      },
      createTweetOnEnterPressEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.updateCreateTweetOnEnterPress(this.userProfile.uid, val)
          .catch(() => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          });
      },
      shouldSplitLongText(val, old) {
        if (old === null) return;
        dao.userProfile.updateShouldSplitLongText(this.userProfile.uid, val)
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          });
      },
      isGhostwriter(currentVal, oldVal) {
        if (oldVal !== currentVal && currentVal !== this.userProfile.isGhostwriter) {
          this.updateGhostwriterSettings('isGhostwriter', currentVal);
        }
      },
      userProfile(val, old) {
        // The time zone should always be a 'clone' of the profile's time zone
        this.timezone = val.timezone.replace(/_/g, ' ');
        this.updateGumroadConnectionStatus();
        this.updateEmailSettings();
        this.isGhostwriter = val.isGhostwriter;
        this.ghostwriterName = val.ghostwriterName ? val.ghostwriterName : '';
      },
      showInspirationTweetsInComposer(val, old) {
        if (old === null) return;
        dao.userProfile.updateShowInspirationTweetsInComposer(this.userProfile.uid, val)
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          });
      },
      isThreadFinisherEnabled(val, old) {
        if (old === null) return;
        dao.userProfile.toggleThreadFinisher(this.userProfile.uid, val)
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          });
      },
      async shouldSendLinkedInExpirationReminder(val, old) {
        if (old === null) return;

        try {
          await dao.userProfile.toggleLinkedInExpirationReminder(this.userProfile.uid, val);
          this.$notify({
            type: 'success',
            message: 'LinkedIn expiration reminder settings updated successfully!',
          });
        } catch (e) {
          console.log(e);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while updating LinkedIn expiration reminder settings.`,
            type: 'warning',
          });
        }
      },
    },
    created() {
      this.timezone = this.userProfile.timezone.replace(/_/g, ' ');
      this.autoRT = this.userProfile.autoRT;
      this.createTweetOnEnterPressEnabled = this.userProfile.settings.createTweetOnEnterPressEnabled;
      this.shouldAutoFillEmptyComposer = this.userProfile.settings.shouldAutoFillEmptyComposer;
      this.showInspirationTweetsInComposer = this.userProfile.settings.showInspirationTweetsInComposer;
      this.shouldSplitLongText = this.userProfile.settings.shouldSplitLongText;
      this.inspirationTweetsType = this.userProfile.settings.inspirationTweetsType;
      this.resetTweetshotPreview();
      this.updateGumroadConnectionStatus();
      this.updateEmailSettings();
      this.linkedInExpiredWarning();
      this.theme = this.userProfile.settings.theme;
      this.isGhostwriter = this.userProfile.isGhostwriter;
      this.isThreadFinisherEnabled = this.userProfile.settings.isThreadFinisherEnabled;
      this.shouldSendLinkedInExpirationReminder = this.userProfile.settings.shouldSendLinkedInExpirationReminder;
    },
    methods: {
      ...mapActions(['fetchTiktokUserDetails']),
      addUser () {
        this.isAddUser = true
      },
      editUser () {
        this.isEditUser = true
      },
      async updateGhostwriterSettings(fieldName, fieldValue) {
        try {
          await controller.users.updateGhostwriterSettings(
            this.currentUser,
            this.userProfile.uid,
            fieldName,
            fieldValue
          );

          this.$notify({
            type: 'success',
            message: 'Ghostwriter settings updated successfully!',
          });
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while updating the ghostwriter settings.`,
            type: 'warning',
          });
        }
      },
      createManagerInvitationToken: function () {
        return controller.createManagerInvitationToken(this.currentUser, this.userProfile.uid);
      },
      deleteManagerInvitationToken: async function (tokenToDelete) {
        this.deletingManagerInvitationTokens.push(tokenToDelete);
        await controller.deleteManagerInvitationToken(this.currentUser, this.userProfile.uid, tokenToDelete);
        const index = this.deletingManagerInvitationTokens.indexOf(tokenToDelete);
        this.deletingManagerInvitationTokens.splice(index, 1);
        return;
      },
      removeManager: async function (managerUserId) {
        this.removingManagers.push(managerUserId);
        await controller.removeManager(this.currentUser, this.userProfile.uid, managerUserId)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Account Manager successfully removed!',
            });
          })
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while removing the account manager.`,
              type: 'warning',
            });
          });
          const index = this.removingManagers.indexOf(managerUserId);
          this.removingManagers.splice(index, 1);
          return;
      },
      allowManagerToSchedulePosts: function (managerUserId) {
        const updatedManagers = this.userProfile.managers.map((manager) => {
          if (manager.uid === managerUserId) {
            return {
              ...manager,
              cannotSchedulePosts: false,
            };
          }
          return manager;
        })
        return dao.userProfile.updateManagers(this.userProfile.uid, updatedManagers);
      },
      preventManagerToSchedulePosts: function (managerUserId) {
        const updatedManagers = this.userProfile.managers.map((manager) => {
          if (manager.uid === managerUserId) {
            return {
              ...manager,
              cannotSchedulePosts: true,
            };
          }
          return manager;
        })
        return dao.userProfile.updateManagers(this.userProfile.uid, updatedManagers);
      },
      quitAsManager: async function (managedAccountUserId) {
        this.removingManagedAccounts.push(managedAccountUserId);
        await controller.removeManager(this.currentUser, managedAccountUserId, this.userProfile.uid)
          .then(() => {
            store.dispatch('disconnectAdditionalAccount', managedAccountUserId);
            this.$notify({
              type: 'success',
              message: 'Bye-bye!',
            });
          })
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while quitting as an account manager.`,
              type: 'warning',
            });
          });
          const index = this.removingManagedAccounts.indexOf(managedAccountUserId);
          this.removingManagedAccounts.splice(index, 1);
          return;
      },
      async disconnectAdditionalAccount(accountId) {
        try {
          await controller.removeAdditionalTwitterAccount(this.currentUser, this.userProfile.uid, accountId);
          store.dispatch('disconnectAdditionalAccount', accountId);
          this.$notify({ type: 'success', message: 'Additional account removed successfully!', });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while trying to remove the additional account.`,
            type: 'warning',
          });
        }
      },
      async disconnectCurrentAccount() {
        try {
          localStorage.removeItem('currentUserId');
          await controller.removeAdditionalTwitterAccount(this.currentUser, this.currentUser.uid, this.userProfile.uid);
          this.$notify({ type: 'success', message: 'Account removed successfully!', });
          setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while trying to remove the account.`,
            type: 'warning',
          });
        }
      },
      displayPlaceHolderImage: function (e) {
        e.target.src = '/img/tweetshot_placeholder.jpg';
      },
      connectGumroad: function () {
        this.$auth.authenticate('gumroad')
          .then(result => {
            const data = result.data;
            const saveInfo = dao.integrations.updateGumroadAccessToken(this.userProfile.uid,
              data.accessToken,
              data.refreshToken,
              new Date(data.createdAt));
            saveInfo.then(() => {
              this.$eventStore.connectGumroad();
              this.$notify({
                message: 'Your Gumroad account has successfully been connected to Hypefury!',
              });
              return null;
            });
          })
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while connecting your Gumroad account.`,
              type: 'warning',
            });
          });
      },
      connectThreads: async function () {
        try {
          const result = await this.$auth.authenticate('threads');
          await this.saveThreadsData(result.data);
        } catch (error) {
          // We're ignoring the error because there is an issue with Threads where if the user is not logged in it throws an error
        }
      },
      async connectThreadsIfRedirectFailed(code) {
        const result = await controller.connectThreads(this.currentUser, code);
        await this.saveThreadsData(result.data);
        window.close();
      },
      async saveThreadsData({ accessToken, userId }) {
        // The token we get lasts for around 2 hours so we refresh it to get a token that only expires if the user logs out
        const [longLivedAccessTokenResult, threadsUserInfo] = await Promise.all(
            [
              controller.getThreadsLongLivedAccessToken(this.currentUser, accessToken),
              controller.getThreadsUserInfo(this.currentUser, accessToken, userId)
            ]
          )

          const threadsData = {
            ...longLivedAccessTokenResult.data,
            userId: threadsUserInfo.data.id,
            username: threadsUserInfo.data.username,
            expiresIn: moment().add(longLivedAccessTokenResult.data.expiresIn, 'seconds').toDate(),
            createdAt: new Date(),
          }

          await dao.integrations.updateThreadsAccessToken(this.userProfile.uid, threadsData);

          this.$notify({
            message: 'Your Threads account has successfully been connected to Hypefury!',
          });
      },
      connectInstagram: function () {
        this.isConnectingInstagram = true;
        this.$auth.authenticate('instagram')
          .then(async result => {
            // The token we get lasts for around 2 hours so we refresh it to get a token that only expires if the user logs out
            const longLivedAccessToken = (await controller.getLongLivedAccessToken(this.currentUser, result.access_token)).data.accessToken;

            const facebookAccountPages = await controller.getPagesOfFacebookAccount(this.currentUser, longLivedAccessToken);

            const accountsWithInstagramIdPromises = facebookAccountPages.map(async page => {
              if (!page.access_token) {
                return null;
              }
              const pageInfo = await controller.getInstagramBusinessAccountOfPage(this.currentUser, page.access_token, page.id);

              const instagramId = lodash.get(pageInfo, 'instagram_business_account.id');

              if (instagramId) {
                const instagramUserInfo = await controller.getInstagramUserInfo(this.currentUser, page.access_token, instagramId);

                // If account does not have a name, default to username
                const name = lodash.get(instagramUserInfo, 'name', instagramUserInfo.username);

                return ({
                  name,
                  id: instagramId,
                  accessToken: page.access_token,
                });
              } else {
                return null;
              }
            })

            const accountsWithInstagramId = (await Promise.all(accountsWithInstagramIdPromises)).filter(account => account !== null);

            if (!accountsWithInstagramId.length) {
              this.isConnectingInstagram = false;
              return this.swalModal({
                title: 'Oops!',
                html: `We couldn't find an Instagram Business account linked to a Facebook page.<br><br>
                  Please follow
                  <a target="_blank" href="https://www.facebook.com/help/502981923235522">these steps</a>
                  and make sure your IG Business account is linked to a Facebook Page.`,
                type: 'warning',
              });
            }

            const saveInfo = dao.integrations.updateInstagramAuthData(
              this.userProfile.uid,
              accountsWithInstagramId[0].name,
              accountsWithInstagramId[0].id,
              longLivedAccessToken,
              new Date(),
             accountsWithInstagramId
            );

            return saveInfo.then(() => {
              this.$eventStore.connectInstagram();
              this.$notify({
                message: 'Your Instagram account has successfully been connected to Hypefury!',
              });
              this.isConnectingInstagram = false;
              return null;
            });
          })
          .catch((error) => {
            this.isConnectingInstagram = false;
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while connecting your Instagram account.`,
              type: 'warning',
            });
          });
      },
      connectLinkedIn: function (isRefreshing = false) {
        const message = isRefreshing
          ? 'You have successfully refreshed your LinkedIn credentials!'
          : 'Your LinkedIn account has successfully been connected to Hypefury!';

        this.$auth
          .authenticate('linkedin')
          .then((result) => {
            const data = result.data;
            const saveInfo = dao.integrations.updateLinkedInAccessToken(
              this.userProfile.uid,
              data.accessToken,
              new Date(),
              moment().add(data.expiresIn, 'seconds').toDate(),
              data.refreshToken,
              moment().add(data.refreshTokenExpiresIn, 'seconds').toDate(),
            );
            saveInfo.then(() =>
              this.$notify({
                message,
              })
            );
          })
          .catch((error) => {
            console.error(error);
            const warningMessage = `An error has occurred while ${
              isRefreshing
                ? 'trying to refresh your LinkedIn credentials'
                : 'connecting your LinkedIn account'
            }.`;
            this.swalModal({
              title: 'Sorry',
              text: warningMessage,
              type: 'warning',
            });
          });
      },
      continuePremiumFeature: function() {
        if (this.premiumFeatureOnHold === 'createManagerInvitationToken') {
          this.createManagerInvitationToken();
        }
      },
      connectFacebook: async function () {
        await dao.integrations.deleteFacebookInformation(this.userProfile.uid);
        this.$auth
          .authenticate('facebook')
          .then((data) => {
            dao.integrations.updateFacebookAccessToken(
              this.userProfile.uid,
              data.access_token,
              new Date(),
              moment().add(data.expires_in, 'seconds').toDate(),
            );
          })
          .catch((error) => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while connecting your Facebook account.`,
              type: 'warning',
            });
          });
      },
      async connectYouTube() {
        try {
          this.isTryingToConnectYouTube = true;
          const result = await this.$auth.authenticate('google');
          if (!result.data.refreshToken) {
            this.swalModal({
              title: 'Heads-up!',
              html: `
              <p>Your YouTube session will expire in about 1 hour.</p>
            <p>To keep things running smoothly, please follow these steps to extend your session:</p>
            <ul>
              <li>Go to <a href="https://myaccount.google.com/connections" target="_blank">Google Account Connections</a></li>
              <li>Remove our app from your connections.</li>
              <li>Reconnect your YouTube account through our app.</li>
            </ul>
           <p>If you need any help, feel free to reach out. Thanks for being with us!</p>
              `,
              type: 'warning',
            });
          }
          await dao.userProfile.updateGoogleAccessToken(
            this.userProfile.uid,
            result.data.accessToken,
            result.data.refreshToken,
            moment().add(result.data.expiresIn, 'seconds').toDate()
          );

          const channels = await controller.getUserYouTubeChannels(
            this.currentUser,
            this.userProfile
          );
          this.youtubeChannels = lodash
            .get(channels, 'items', [])
            .map((channel) => ({ id: channel.id, name: channel.snippet.title }));
          if (this.youtubeChannels.length === 1) {
            const channel = this.youtubeChannels[0];
            await dao.userProfile.setYouTubeChannel(this.userProfile.uid, channel.id, channel.name);
            this.youtubeChannels = null;
            this.$notify({
              message: 'Your YouTube channel has successfully been connected to Hypefury!',
            });
          }
          this.isTryingToConnectYouTube = false;
        } catch (error) {
          this.isTryingToConnectYouTube = false;
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while connecting your YouTube account.`,
            type: 'warning',
          });
        }
      },
      connectTikTok: async function () {
        try {
          const { isConfirmed } = await this.swalModal({
            title: 'Connect TikTok account',
            html: 'By connecting your TikTok account, you agree to TikTok\'s terms of service. Please note that you can only post once every 4 hours.',
            showCancelButton: false,
            confirmButtonText: 'Accept & Connect',
            type: 'info'
          });

          if (isConfirmed) {
            try {
              const response = await this.$auth.authenticate('tiktok');
              const data = response.data;

              await dao.integrations.updateTikTokAccessToken(
                this.userProfile.uid,
                data.accessToken,
                moment().add(data.expiresIn, 'seconds').toDate(),
                data.refreshToken,
                moment().add(data.refreshTokenExpiresIn, 'seconds').toDate(),
                data.openId,
                data.scope,
                data.userInfo
              );

              await this.fetchTiktokUserDetails();

              this.$notify({
                type: 'success',
                message: 'TikTok account connected successfully!'
              });
            } catch (authError) {
              console.error(authError);
              this.swalModal({
                title: 'Authentication Failed',
                text: 'Unable to connect to TikTok. Please try again.',
                type: 'error'
              });
            }
          }
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while connecting your TikTok account.',
            type: 'warning'
          });
        }
    },
      disconnectGumroad: function () {
        dao.integrations.deleteGumroadInformation(this.userProfile.uid);
      },
      disconnectInstagram: function () {
        dao.integrations.deleteInstagramInformation(this.userProfile.uid);
      },
      disconnectLinkedIn: function () {
        dao.integrations.deleteLinkedInInformation(this.userProfile.uid);
      },
      disconnectFacebook: function () {
        dao.integrations.deleteFacebookInformation(this.userProfile.uid);
      },
      disconnectYouTube: function () {
        dao.integrations.deleteYouTubeInformation(this.userProfile.uid);
      },
      disconnectThreads: function () {
        dao.integrations.deleteThreadsInformation(this.userProfile.uid);
      },
      disconnectTikTok: function () {
        dao.integrations.deleteTikTokInformation(this.userProfile.uid);
      },
      saveTweetshotSettings: function () {
        this.tweetshotSettings.isSavingTweetshotSettings = true;
        dao.integrations.updateTweetshotSettings(this.userProfile.uid, this.tweetshotSettings.tweetshotLayout, this.tweetshotSettings.tweetshotTheme)
          .then(() => {
            this.$notify({'type': 'success', 'message': 'Instashot settings saved!'});
            this.tweetshotSettings.canSaveTweetshotSettings = false;
          })
          .catch(error => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while saving the image.`,
              type: 'warning',
            });
          })
          .then(() => {
            this.tweetshotSettings.isSavingTweetshotSettings = false;
            if (this.userProfile.customerStatus === 'none') {
              this.premiumFeatureOnHold = 'instagram.tweetshotCustomization';
              this.upgradeToPremiumPopUpContent =
              `The layout is saved, but it'll only be used if you grab a ${this.getPlanLabel('standard')} subscription,`
              + ` otherwise Hypefury will use the default layout.<br/>`
              + `Unlock Insta-shot customization and many other powerful features?`;
              this.showUpgradeToPremiumPopUp = true;
            }
          });
      },
      updateTimezone: function (value) {
        const systemTz = value.replace(/ /g, '_');
        if (!moment.tz.names().includes(systemTz)) {
          return;
        }

        this.timezone = systemTz;

        const that = this;
        firebase
          .firestore()
          .collection('users')
          .doc(this.userProfile.uid)
          .update({ timezone: systemTz })
          .then(() => {
            that.$notify({ type: 'success', message: 'Time zone updated.' });
          })
          .catch((error) => {
            console.error(error);
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while updating the time zone.`,
              type: 'warning',
            });
          });
      },
      firestore: function () {
        return firebase.firestore()
      },
      updateEmailSettings: function () {
        this.isPublishFailureEmailEnabled = !this.userProfile.emailNotificationsSettings.isPublishFailureEmailDisabled;
        this.isWeeklyDigestEnabled = !this.userProfile.emailNotificationsSettings.isWeeklyDigestDisabled;
        this.isDailyDigestEnabled = !this.userProfile.emailNotificationsSettings.isDailyDigestDisabled;
        this.isEmptyQueueEmailEnabled = !this.userProfile.emailNotificationsSettings.isEmptyQueueEmailDisabled;
      },
      updateGumroadConnectionStatus: function () {
        this.isConnectedToGumroad = lodash.get(this.userProfile, 'integrations.gumroad.tokenInfo.accessToken');
      },
      linkedInExpiredWarning() {
        if (
          this.isUserConnectedToLinkedIn &&
          this.linkedInTokenInfo &&
          (this.linkedInTokenInfo.isExpired || this.linkedInTokenInfo.expiresInDays <= 7)
        ) {
          const isExpired = this.linkedInTokenInfo.isExpired;
          const expiresInDays = this.linkedInTokenInfo.expiresInDays;
          const message = isExpired
            ? 'Your LinkedIn credentials have expired. Please re-authenticate with LinkedIn again.'
            : expiresInDays <= 7
            ? `Your LinkedIn credentials expire in ${expiresInDays} days. Please refresh them.`
            : '';

          this.swalModal({
            title: 'Sorry',
            text: message,
            type: 'warning',
          });
        }
      },
      async selectFacebookPage(pageId, pageName) {
        try {
        this.swalModal({
          title: `Select Facebook page`,
          text: `Are you sure you want to manage the ${pageName} page?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            await dao.userProfile.setFacebookPage(this.userProfile.uid, pageId);
          },
        });
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'There has been a problem while selecting your Facebook page. Please try again.',
            type: 'warning',
          });
        }
      },
      openDeleteAccountModal () {
        this.isDeleteAccountModalVisible = true;
      },
      closeDeleteAccountModal () {
        this.isDeleteAccountModalVisible = false;
      },
      updateInstagramPage(pageId) {
        const updatedPages = this.userProfile.integrations.instagram.pages.find(page => page.id === pageId);
        if (updatedPages) {
          dao.integrations.updateInstagramAccount(this.userProfile.uid, updatedPages);
        }
      },
      async selectYouTubeChannel(channelId, channelName) {
        try {
          this.swalModal({
            title: `Select YouTube Channel`,
            text: `Are you sure you want to manage the ${channelName} channel?`,
            type: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            preConfirm: async () => {
              await dao.userProfile.setYouTubeChannel(this.userProfile.uid, channelId, channelName);
              this.youtubeChannels = null;
              this.$notify({
                message: 'Your YouTube channel has successfully been connected to Hypefury!',
              });
            },
          });
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'There has been a problem while selecting your YouTube channel. Please try again.',
            type: 'warning',
          });
        }
      },
    },
    mixins: [
      BetaFeaturesMixin,
      LinkedInMixin,
      SwalModalMixin,
      FacebookMixin,
      InstagramMixin,
      MobileMixin,
      LogoutMixin,
      CustomerStatusMixin,
      YouTubeMixin,
      TweetshotSettingsMixin,
      ThreadsMixin,
      TiktokMixin,
    ],
    components: {
      VueSelect,
      DeleteAccountModal,
      ExternalApps,
    },
    async mounted() {
      const self = this;

      if (this.$route.query.code) {
        this.connectThreadsIfRedirectFailed(this.$route.query.code);
      }

      async function processManagerInvitation() {
        if (self.$route.query && self.$route.query.managerInvitationToken) {
          self.isAddingManager = true;
          try {
            const response = await controller.addAccountToManage(self.currentUser,
              store.getters.getUserProfile.uid,
              self.$route.query.managerInvitationToken)
            self.isAddingManager = false;

            removeQueryParamFromURL(self.$router, self.$route, 'managerInvitationToken');

            if (response.status === 200) {
              self.$notify({ message: `You are now managing @${response.data.ownerUsername}!` });
            }
          } catch (error) {
            self.isAddingManager = false;
            let message = `The token invitation link doesn't exist or has expired.`;
            if (error.response.status === 403) {
              if (error.response.data.message.includes('premium')) {
                self.premiumFeatureOnHold = 'createManagerInvitationToken';
                self.upgradeToPremiumPopUpContent = `Upgrade to the ${this.getPlanLabel('premium')} plan so you can be able to
                  to manage other accounts, and unlock many other powerful
                  features like detailed analytics and hourly tweet import!`;
                self.showUpgradeToPremiumPopUp = true;
                return;
              }

              message = error.response.data.message;
            }
            self.swalModal({
              title: 'Oops!',
              html: message,
              type: 'warning',
            });
            removeQueryParamFromURL(self.$router, self.$route, 'managerInvitationToken');
          } finally {
            self.isAddingManager = false;
          }
        }
      }

      await processManagerInvitation();

      async function processBrowserExtensionLogin() {
        const isLoginRequested = Boolean(self.$route.query.hypefuryExtension);

        if (isLoginRequested) {
          removeQueryParamFromURL(self.$router, self.$route, 'hypefuryExtension');
          if (!chrome || !chrome.runtime) {
            return null;
          }
          const message = `It appears that you installed the Hypefury Browser Extension.<br>
            Do you want to link it to your account?`;
          self.swalModal({
            title: 'Hypefury Browser Extension',
            html: message,
            type: 'question',
            showCancelButton: true,
            confirmButtonText: 'Let\'s go!',
            cancelButtonText: 'Not interested',
            preConfirm: async () => {
              Swal.getConfirmButton().textContent = 'Connecting...';
              Swal.getCloseButton().disabled = true;

              try {
                const apiKey =
                  (await controller.generateAPIKey(self.currentUser, self.userProfile.uid)).apiKey;

                const message = { messageType: 'link_app', apiKey };
                chrome.runtime.sendMessage(config.chromeExtensionId, message, (response) => {
                  return true;
                });
              } catch (error) {
                console.error(error);
                this.swalModal({
                  title: 'Sorry',
                  text: `An error has occurred while linking the extension to Hypefury. Please try doing it manually.`,
                });
              }
            },
          });
        }
      }

      processBrowserExtensionLogin();

      switch (this.$route.hash) {
        case '#account-managers':
          this.activeTab = 1;
          break;
        case '#linkedin':
        case '#gumroad':
        case '#facebook':
        case '#instagram':
        case '#tiktok':
        case '#external-apps':
          this.activeTab = 2;
          break;
        case '#evergreen':
        case '#composer':
          this.activeTab = 3;
          break;
        case '#email-notifications':
          this.activeTab = 4;
          break;
        case '#connection':
          this.activeTab = 2;
          break;

        // no default
      }
      this.isGhostwriter = this.userProfile.isGhostwriter;
      this.ghostwriterName = this.userProfile.ghostwriterName ? this.userProfile.ghostwriterName : '';
      this.$eventStore.visitSettingsPage();
    },
  };
</script>
